.lr-summary {
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin-top: 20px;
}

.lr-summary p {
  margin: 0px;
}
