.legend {
    flex-wrap: wrap;
    display: flex;
    margin: 1rem auto;
    font-size: 90%;
    justify-content: left;
}

.legend-item {
    display: flex;
    margin-right: 1.6rem;
    align-items: center;
}

.legend-color {
    border: 1px solid #888;
    width: 1rem;
    height: 1rem;
    margin: .2rem;
}
