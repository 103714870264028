@import url(https://rsms.me/inter/inter.css);
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: "Inter var", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*  APPARAT CSS */
.bg-white {
  background-color: #ffffff;
}

.react-daterange-picker__wrapper {
  border: none !important;
}
.profile-photo {
  width: 26px;
  margin-right: 10px;
  border-radius: 50%;
}

h3 {
  text-transform: uppercase;
  font-size: 22px;
  color: #5a636d;
}

h4 {
  text-transform: uppercase;
  font-size: 18px;
  color: #5a636d;
}

.w50 {
  width: 50%;
}
.w40 {
  width: 40%;
}

.autocollapsegrid {
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.main-nav {

}

.main-nav a {
  display: inline-flex;
  color: #555555;
  margin-right: 1em;
  padding: 0.3em;
}

.main-nav .active > a {
  text-decoration: none;
  background-color: green;
  color: white;
}

.main-nav a:hover {
  text-decoration: none;
}

.main-nav .gustav a {
  height: 1.5em;
  font-size: 1.2em;
  color: black;
  display: inline-block;
}

.main-nav .gustav .fas {
  line-height: 1;
}

.lr-summary {
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin-top: 20px;
}

.lr-summary p {
  margin: 0px;
}

.legend {
    flex-wrap: wrap;
    display: flex;
    margin: 1rem auto;
    font-size: 90%;
    justify-content: left;
}

.legend-item {
    display: flex;
    margin-right: 1.6rem;
    align-items: center;
}

.legend-color {
    border: 1px solid #888;
    width: 1rem;
    height: 1rem;
    margin: .2rem;
}

